import website from '../assets/website.png'
import instagram from '../assets/instagram.png'
import linkedin from '../assets/linkedin.jpg'
import youtube from '../assets/youtube.jpeg'
import github from '../assets/github.png'
import twitter from '../assets/twitter.png'
import tiktok from '../assets/tiktok.png'
import discord from '../assets/discord.png'
import { ItemType } from '../ts';

const items: ItemType[] = [{
    "title": "Website",
    "subtitle": "Our homestead 🏡",
    "image": website,
    "link": "https://noxity.io" //your personal website or portfolio  link
},
{
    "title": "Twitter",
    "subtitle": "IaaS platform that is more powerful than a polar bear 🐻‍❄️ 💪",
    "image": twitter,
    "link": "https://twitter.com/noxityio"
},
{
    "title": "Discord",
    "subtitle": "Networking nerds assemble! 🤓",
    "image": discord,
    "link": "https://discord.noxity.io"// tiktok profile link 
},
{
    "title": "Tiktok",
    "subtitle": "Cloud platform battling in dimensions of bugs & development ⚔️",
    "image": tiktok,
    "link": "https://www.tiktok.com/@noxityio"
},
{
    "title": "YouTube",
    "subtitle": "Seriously cool videos. Pinky promise! 🤩",
    "image": youtube,
    "link": "https://www.youtube.com/@noxityio" 
},
{
    "title": "GitHub",
    "subtitle": "Wizards who turn code into magic ✨",
    "image": github,
    "link": "https://github.com/noxitylabs"
},
{
    "title": "Instagram",
    "subtitle": "We leak our pictures on here 🫢",
    "image": instagram,
    "link": "https://instagram.com/noxityio"
},
{
    "title": "LinkedIn",
    "subtitle": "Stock this if you are (business) networkerer 🤓",
    "image": linkedin,
    "link": "https://www.linkedin.com/company/noxity/"
}]

export default items
